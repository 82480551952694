import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "稼働中の Amazon Aurora PostgreSQL を暗号化した話",
  "date": "2023-12-22T07:39:11.000Z",
  "slug": "entry/2023/12/22/163911",
  "tags": [],
  "hero": "./2023_12_22.png",
  "heroAlt": "稼働中の Amazon Aurora PostgreSQL を暗号化した話"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。
プロダクト開発室第二開発グループ所属の古川です。`}</p>
    <p>{`私は 22 年新卒としてメドレーに入社し、現在はインフラとアプリケーションを 5 : 5 の割合ぐらいで開発・運用・保守などを行っています。
学生時代からインフラ周りに触る機会も多く、入社前に `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/certification/certified-solutions-architect-professional/"
      }}>{`AWS Certified Solutions Architect - Professional`}</a>{` を取得しました。`}</p>
    <p>{`大のコーヒ好きで自宅で生豆から焙煎をし、毎朝豆を挽き、抽出したコーヒーを飲むことが日々の幸せです。
好きなコーヒー豆の種類は インドネシア・スマトラ島産・アラビカ種のマンデリン( G1 )の中深煎りです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8bf9a01e1edc2b82741cb2989bfaceab/df51d/_dev202312_000.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFWZFy3EIf/xAAcEAACAQUBAAAAAAAAAAAAAAABAwIABBEhIjL/2gAIAQEAAQUCuHch2g1mJ+qi0gf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EAB0QAAIBBAMAAAAAAAAAAAAAAAABAhAREiExYaH/2gAIAQEABj8CUYlsjS8o+jhH/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERIVExQf/aAAgBAQABPyFFkp6xvhoTsNedCrctGnF8IGX/2gAMAwEAAgADAAAAEGPf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EIbH/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKZX/8QAHBABAQACAgMAAAAAAAAAAAAAAREAITFBUXGx/9oACAEBAAE/EHbA0GMOsbcxG13gzysQnvFDhBbxvNqJKF7aw4VDtp8TP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202312 000",
            "title": " dev202312 000",
            "src": "/static/8bf9a01e1edc2b82741cb2989bfaceab/e5166/_dev202312_000.jpg",
            "srcSet": ["/static/8bf9a01e1edc2b82741cb2989bfaceab/f93b5/_dev202312_000.jpg 300w", "/static/8bf9a01e1edc2b82741cb2989bfaceab/b4294/_dev202312_000.jpg 600w", "/static/8bf9a01e1edc2b82741cb2989bfaceab/e5166/_dev202312_000.jpg 1200w", "/static/8bf9a01e1edc2b82741cb2989bfaceab/d9c39/_dev202312_000.jpg 1800w", "/static/8bf9a01e1edc2b82741cb2989bfaceab/df51d/_dev202312_000.jpg 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`さて、今回の記事では私が携わっているプロダクトで運用している `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/rds/aurora/"
      }}>{`Amazon Aurora`}</a>{`（以下 Aurora ）を暗号化した時の検証・暗号化の作業について紹介させていただきます。`}</p>
    <p>{`私が検証を始める前の Aurora 周りに関する私自身の認識は以下の通りでした。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/AWS"
        }}>{`AWS`}</a>{` のサービス全体は大体知っている`}</li>
      <li parentName="ul">{`論理レプリケーションというものが存在することは知っており触ったこともあるが、実際に稼働中のものに対して実施したことはない`}</li>
      <li parentName="ul">{`非暗号化の Aurora DB クラスターを暗号化するのは大変であるという理解`}</li>
    </ul>
    <p>{`上記のような認識の新卒エンジニアが、検証をしながら大きな問題なく暗号化作業を実施した過程を紹介できればと思います。`}</p>
    <h1>{`暗号化を実施する Aurora とその周辺アプリケーションについて`}</h1>
    <h2>{`影響範囲と暗号化をする Aurora DB クラスターについて`}</h2>
    <p>{`今回暗号化を行う Aurora DB クラスターの周辺アプリケーションはざっくり以下のようになっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "492px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0c14dfd1b222359e741d87fc06b5949c/5c6e9/_dev202312_001.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACfklEQVQ4y42U20sUYRiH96+poLqsP8Aob+oiKK/sKrxMqIsKoRRKRfEQgbGBokUniIQy8ABb6IWboajgbqDWHkY3dd2Z2TnP7sw8MbvuuLlqffDONzO888x7+L1fiAPL87z9h81tnJ8z6JEOjOlerLnBv3wzwyLCQBbh6Q56GtjeIHQYrGDbGKaOmdhE/NSK3HkSufss8sAFDF3HskzyWZnlK3GW6uJ8P71E9rMC4gYhH1IBVXZVUbBtCzu9TW60BSV8Ee1VI/lwPbZl4rgOmqgSa1wl3rDOcl2c3IRWBvoA13WpBuuahocHv3exF94ijzQgvWtCHb0VZGOqNqsPU/y4k2Dl5hq5eQOyR6SsaRquf5NK4cgqqmoh7UqYRhFsm4KqIfxKkEkLCIkk4s4WoiRSiMUJ9fb2MT4+QbFYDMAloA+3C7grC7jzU3gLEdzFGbxkEnwT0pDes1QKkikQBELdPX00N9/m22w0iNIwDHCdcmqzA0hdp5CfnCM/eLnsc4yFhoZf0NPTz9TkZBBdNptFVxW0AuyMPUIJ16MMXUUZuY7nFP2/4rluaT9oocftndy738KXSKQsmUIBRVEo2lapjtpUB0r/efSXDSjP6vAO02vVCkWjUVpb24jHYoGjrut4XqktFKUNxMUxtuZGya/tl+VIoF+vTGaztsuuF0D9t+7e7l+PYJWBfncrHQ50WBWhs/4Vfewu5mQb5nT/vyOs1mDFya+hZRoUAGmiHanjBFLXGfLhSzVTVQM8bPQsy8LQVQwXsh8foPqj9/oGyvP6/TL8K8La02bvoFh6T27oGuKbJtQPzf+fcjUs+MDz8OWtWQ45WcUsHi8ZH/gHgyilqyB4FF0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202312 001",
            "title": " dev202312 001",
            "src": "/static/0c14dfd1b222359e741d87fc06b5949c/5c6e9/_dev202312_001.png",
            "srcSet": ["/static/0c14dfd1b222359e741d87fc06b5949c/5a46d/_dev202312_001.png 300w", "/static/0c14dfd1b222359e741d87fc06b5949c/5c6e9/_dev202312_001.png 492w"],
            "sizes": "(max-width: 492px) 100vw, 492px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`実際に暗号化をする Aurora DB クラスターのエンジンは Aurora PostgreSQL で、主に `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/fargate/"
      }}>{`AWS Fargate`}</a>{` 上にある `}<a parentName="p" {...{
        "href": "https://rubyonrails.org/"
      }}>{`Ruby on Rails`}</a>{` (以下 Rails )で書かれた API サーバからのアクセスがあります。
インフラ構築は IaC ( Infrastructure as Code ) ツールの `}<a parentName="p" {...{
        "href": "https://www.terraform.io/"
      }}>{`Terraform`}</a>{` で管理しています。
また、暗号化対象の Aurora DB クラスターのデータ量は 5GiB 以下で比較的容量は小さめです。`}</p>
    <h2>{`Aurora DB クラスターの周辺について`}</h2>
    <p>{`複数プロダクトへ同時にアクセスを行うクライアントから、本クラスターにはアクセスが頻繁にあります。
そのため長時間読み込み処理ができないと他のプロダクトにも影響がでる可能性があります。`}</p>
    <p>{`また、本 Aurora DB クラスターに対して書き込みが行われるメイン機能が実行されるタイミングでは、アプリケーションへの影響を最小限にしたいと考えました。
API サーバ内では基本的にロールバック処理がされているので、データ不整合は起きませんが処理に失敗はします。
当たり前ですがユーザ体験は悪くなるので、できるだけ失敗しないように暗号化作業を行う必要があります。`}</p>
    <h1>{`実施方法の検討`}</h1>
    <p>{`実施方法の検討をする上で以下の観点を意識しました。`}</p>
    <ul>
      <li parentName="ul">{`暗号化を実施する時間帯`}</li>
      <li parentName="ul">{`暗号化するためにかかる時間`}</li>
      <li parentName="ul">{`移行までの準備と労力・アプリケーションコードへの依存`}</li>
    </ul>
    <h2>{`暗号化を実施する時間帯`}</h2>
    <p>{`前提として`}<strong parentName="p">{`ライターインスタンスの停止は 10 分程度にしたい`}</strong>{` という目標のもと時間帯を検討しました。この時間は暗号化実施時に予期せぬことが起き、元の状態に戻すことが必要になった時も踏まえ、できるだけ短く・かつクライアントの影響範囲が比較的少なくなるように決めたものです。
また、暗号化を実施する Aurora DB クラスターは複数プロダクトからのアクセスがあります。その中でも、今回は書き込み処理が行われる機能の利用が少ない時間帯に実施することにしました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/cloudwatch/"
      }}>{`Amazon CloudWatch`}</a>{` の Logs や `}<a parentName="p" {...{
        "href": "https://www.datadoghq.com/ja/"
      }}>{`Datadog`}</a>{` の情報をもとに、あるメイン機能は夜間・休日の利用が少ない傾向にあり、特に土曜日の深夜 26:00 が 1 番少ないと判明したのでその時間に実施することにしました。`}</p>
    <h2>{`暗号化するためにかかる時間`}</h2>
    <p>{`暗号化をするために行う作業はいくつか存在し、その内容とかかる時間が異なります。
例えば、 Rails がアクセスする DB ホスト名を変更しただけだとコネクションプールが残ってしまい、変更前の非暗号化クラスターに接続しようとしてしまいます。そのため、ホスト名を変更した後に `}<strong parentName="p">{`AWS Fargate サービスの再起動が必要`}</strong>{`で、その時間も安定稼働までに確保しなければなりません。`}</p>
    <p>{`以上のような時間が発生することと、ライターインスタンスの停止は 10 分程度にしたいという目標を踏まえ、検証時に時間を計測しました。`}</p>
    <h2>{`移行までの準備と労力・アプリケーションコードへの依存`}</h2>
    <p>{`基本的に 1 人で検証・移行作業を行う、かつ私自身が別のプロジェクトも並行して進めているため、準備に時間がかかりすぎてしまうものは採用しないことにしました。
そのためできるだけ変更箇所は少ないもの、特に`}<strong parentName="p">{`できればアプリケーションコードの変更が極力ない方法`}</strong>{`というのを検証時点で決めておきました。`}</p>
    <p>{`上記を踏まえ、以下の 3 つの方法で検証をしたのでそれぞれについて説明します。`}</p>
    <ul>
      <li parentName="ul">{`スナップショットを用いて新規暗号化済みクラスターを作成する方法（方法 1 ）`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aws.amazon.com/jp/dms/"
        }}>{`AWS Database Migration Service`}</a>{` (以下 AWS DMS )を用いて継続的論理レプリケーションを使用する方法（方法 2 ）`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`pg_dump`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`pg_restore`}</code>{` を使用する方法（方法 3 ）`}</li>
    </ul>
    <h3>{`スナップショットを用いて新規暗号化済みクラスターを作成する方法`}</h3>
    <p>{`こちらの方法は Web 上で「 Aurora 暗号化 後から」などと検索すると良くヒットする方法です。
すでに起動している非暗号化クラスターのスナップショットを取得し、そのスナップショットから暗号化済みの新クラスターを復元する方法です。データの不整合が起きないように、スナップショットを取得する段階から書き込みがされないように制限する必要があります。`}</p>
    <p>{`準備することはほぼなく簡単に実施できるのですが、ライターインスタンスの停止時間が長すぎるため不採用としました。
検証時ではそれぞれ以下の時間だけかかり、ライターインスタンスの停止許容時間を大きく上回り現実的ではないと判断しました。`}</p>
    <ul>
      <li parentName="ul">{`スナップショットの取得時間：3 分`}</li>
      <li parentName="ul">{`暗号化済み新クラスターの作成：約 30 分`}</li>
      <li parentName="ul">{`アプリケーションから接続する DB ホストの切り替え：数秒程度`}</li>
      <li parentName="ul">{`AWS Fargate の再起動：3 分強`}</li>
    </ul>
    <h3>{`AWS DMS を用いて継続的論理レプリケーションを使用する方法`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "697px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b9c942bfc0f8910cc80ff8894bbe4f82/7422e/_dev202312_002.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACM0lEQVQ4y52TS08UQRDH9+N48qQxJhw04cAJY7x40BhuYDh68jsYz+rB4I2bCMRHjJKYYEBwFwIbwooYHssuM9s7j53umeme+ZnpXRYRNiZ2UqlO/6v+9eoqMeAYY6xeXq1w9cYod8cmcdyWfcuybJAbpX8RLq2UuXx9hDv3xjl23P8nzPPcatH2mJn/wMKXr2htzmADCQuDv8W+Z/mFgSz+p+2JFISDouW9qtxDw5snDp9fCqKgF6jwv7isboZpmqKkQqlTkZEiJ2G7LHg8us7TiS3EXoDnuYhiOHFMrlRXpCTPMhvEEqpIYVJjw56UkfXKFc2Yj9MHLM43kB1dpGGxfnuKF6nIE31KmJIObLLJNMJvEUQ+5wrttyvtY6XU0ey+2Kf+qolpGwwZqTZWirO4tMrI7QeMTTyi0XR6Qbo2RX5JXXP0bJ/GVAOjMkqdNcnazS0qQ1VEJSDs1An3KhzXlsHbYXZujktXhhm+dZ9fO1t0Dio0a8t4u2U6iYOzICgPVfl+bZO0kVJqV32qkz+oPqwhtkOkv4u38ZbDlRninU80D3/yfGqa2XcLJH4dsT7D/rfXBNX3hJ0DjlfabI5vszleQzkxJccRuEcCzw2IIkkSSzASLT2ri+N5PkmSdPukI2TgWiwIPETLs/5tx0dKdXZTinWLrSNEMsbzA9xWCykL5949TpAqsSOI4/j8ptjRZ92fqrXuGxX7qpQk8H2iKCIMQ0uq09RiucVVf6NOvtFv89wimndcwjUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202312 002",
            "title": " dev202312 002",
            "src": "/static/b9c942bfc0f8910cc80ff8894bbe4f82/7422e/_dev202312_002.png",
            "srcSet": ["/static/b9c942bfc0f8910cc80ff8894bbe4f82/5a46d/_dev202312_002.png 300w", "/static/b9c942bfc0f8910cc80ff8894bbe4f82/0a47e/_dev202312_002.png 600w", "/static/b9c942bfc0f8910cc80ff8894bbe4f82/7422e/_dev202312_002.png 697w"],
            "sizes": "(max-width: 697px) 100vw, 697px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`こちらの方法は AWS DMS で論理レプリケーションを行い、旧クラスターの変更を検知して、新クラスターに反映させる方法です。
想定しているライターインスタンスの停止時間は、旧クラスターを読み取り専用に変更 〜 レプリケーションが追いつき DB ホストの変更が終わるまでの時間です。
この停止時間は 5 分弱と想定しており採用するつもりだったのですが、AWS DMS の移行前評価を行った時すぐに実施できないことが明らかになりました。`}</p>
    <p>{`移行前評価の詳細は省きますが、主な原因は Large Object ( LOB ) の制約で、150 以上のカラムがそのままの状態では移行することができかったためです。
そのほとんどが null 許可の制約だったので解消しようとしましたが、テーブル構造だけでなくアプリケーション側の変更もいくつか必要だったため、不採用としました。`}</p>
    <p>{`また論理レプリケーション設定を ON にするためには事前にライターインスタンスの再起動が必要で、結局別のタイミングで少しのダウンタイムは発生してしまうこともあり実施しないことにしました。`}</p>
    <h3>{`pg_dump & pg_restore を使用する方法`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "542px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/61512957279f15f5289099d299666776/c0388/_dev202312_003.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVQ4y52TwWsTQRTG80d5FDwU9FAqDfSkVKRUBUErnhREEaGetBdF8KDgUQUPInoqCiKKoZQ2NE3bTdK4aTbJJtvN7uwmmZ35yW7SNrGthw48Hrxv+N73vXmT4oijlEryplHkXHqasYkLXL99L6lprZM47qT+R7i+YXD67BSnzkxwbe7uPtGJCc1Kldkbd7g4O8f8k+cnVziswvN9nGaTKIpGCI9TmxoGDkUMSAmOu9dltOEe8V5d677C4yzs1hWfn+3y6anN93fikGU9amlIoYZABAghCII4B/SikHLe5cWtIgtXi3xYqNBzPSzLombViHwfghAtBLrT7fPFCgfGEB1BpKNkVrInk/6m0Wb+yhIPpzO8fpRDS5ngcWgZgYrnGkHnQH1KK03kK+yyTRSoEcuyp9jZctkpeNhWeMQ2gHS6SLOF6iYSSfVsyfpMgeV0jtxlAyWGSCNF+DZD53eBHl0Cz0tGIrsygf3lgGw6z+r4GsUHZn+GvZYkO7VBdnKTlck87Z02QRQSVlq4i2s4X7KIUp0gJuhqPM+jUWsQ6gDza4XlsRwr43k2b5YGhE1J7pJBNr3B+oyBdAfdaw5/Xn2jsWTgK0X14wLdH2/6WNtLcnvJZ3Uiz+r5PMX7A4XbJZNCpkzhVxkr18B1+5dFGGLZDWq2TbXRovj+Me7iSzoKhO/TdBy2DZNSxsT4uU1ty6bteQevvL9OHOxZ/Nq+51Ov12m4gmrTxapWabVa+3f//V1/AYRxG34IA9VpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202312 003",
            "title": " dev202312 003",
            "src": "/static/61512957279f15f5289099d299666776/c0388/_dev202312_003.png",
            "srcSet": ["/static/61512957279f15f5289099d299666776/5a46d/_dev202312_003.png 300w", "/static/61512957279f15f5289099d299666776/c0388/_dev202312_003.png 542w"],
            "sizes": "(max-width: 542px) 100vw, 542px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`こちらの方法は、 PostgreSQL クライアントに標準で提供されている `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_dump`}</code>{` と `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_restore`}</code>{` を使用する方法です。
実施する時は、 `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/systems-manager/features/"
      }}>{`AWS Systems Manager`}</a>{` のセッションマネージャーを介してクラスターにアクセス可能な状態を作り、コマンドを実行します。`}</p>
    <p>{`結論からいうとこちらの方法を実際に採用しました。
理由は主に以下になりますが、`}<strong parentName="p">{`暗号化するクラスターのデータサイズが 5GiB 以下`}</strong>{`だったので、本方法でもそこまで時間がかからなかったのが一番の理由です。`}</p>
    <ul>
      <li parentName="ul">{`検証時にライターインスタンスの停止時間は 8 分程度だったこと`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`pg_dump`}</code>{` & `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`pg_restore`}</code>{` ： 4 分弱`}</li>
          <li parentName="ul">{`アプリケーションから接続する DB ホストの切り替え：数秒程度`}</li>
          <li parentName="ul">{`AWS Fargate サービスの再起動： 3 分強`}</li>
        </ul>
      </li>
      <li parentName="ul">{`移行時に実行するシェルスクリプトを用意すれば良いので準備が大変ではないこと`}</li>
      <li parentName="ul">{`検証時に開発環境で実行した後も不具合は確認されなかったこと`}</li>
    </ul>
    <p>{`またこちらの方法は Amazon Aurora PostgreSQL をマイグレーションする時の方法として公式にも掲載されていたので、迷いなく行えると思いました（ `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/dms/latest/sbs/chap-manageddatabases.postgresql-rds-postgresql-full-load-pd_dump.html"
      }}>{`pg_dump and pg_restore`}</a>{` ）。`}</p>
    <p>{`それぞれの検証結果をまとめると以下のようになります。
今回のユースケースでは方法 3 の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_dump`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_restore`}</code>{` を使用した暗号化が良いと判断し、実施することにしました。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`項目`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`方法 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`方法 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`方法 3`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`暗号化するためにかかる時間`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 分弱`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 〜 5 分程度`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 〜 8 分程度`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`移行までの準備と労力`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`小`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`大`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`小`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`アプリケーションコードへの依存`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`なし`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`あり`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`なし`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`実施内容`}</h1>
    <p>{`行った作業内容は以下の通りで、それぞれ説明していきます。
移行時は、私とは別にもう 1 人の方にアラートなどの状況を `}<a parentName="p" {...{
        "href": "https://sentry.io/welcome/"
      }}>{`Sentry`}</a>{` 等で確認していただき、予期せぬ問題が起きていないかを確認します（当たり前ですが、ダブルチェックはすごく大事）。`}</p>
    <ol>
      <li parentName="ol">{`API アプリケーションから Aurora に接続する時には DNS Alias で名前解決するように設定する`}</li>
      <li parentName="ol">{`暗号化された Aurora DB 新クラスターを Terraform で構築する`}</li>
      <li parentName="ol">{`旧クラスターを読み取り専用に変更する`}</li>
      <li parentName="ol">{`移行スクリプトを実行する（実行時間： 3 分強）`}</li>
      <li parentName="ol">{`DNS Alias を旧クラスターから新クラスターに切り替える（実行時間：数秒）`}</li>
      <li parentName="ol">{`AWS Fargate サービスを再起動する（実行時間： 3 分強）`}</li>
    </ol>
    <p><strong parentName="p">{`1. API アプリケーションから Aurora に接続する時には DNS Alias で名前解決するように設定する`}</strong></p>
    <p>{`AWS のマネージドサービスである `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/route53/"
      }}>{`Route 53`}</a>{` で、レコードに旧クラスターのエンドポイントを指定します。エイリアスの名前は、取得済みのドメインからサブドメインを指定しています。API アプリケーションから、そのエイリアス名で DB ホスト名を名前解決できるように設定を変更しておきます。
本 API アプリケーションは DB のホスト名は `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/systems-manager/features/#Parameter_Store"
      }}>{`Parameter Store`}</a>{` で管理していたので、AWS Fargate の再起動を行い起動時に新しい値が読み込まれるようにしました。`}</p>
    <p><strong parentName="p">{`2. 暗号化された Aurora DB 新クラスターを Terraform で構築する`}</strong></p>
    <p>{`移行の前に暗号化済みの新クラスター作成しておきます。暗号化には `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/kms/"
      }}>{`AWS Key Management Service`}</a>{` を使用します（ `}<a parentName="p" {...{
        "href": "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/kms_key"
      }}>{`Terraform の公式リファレンス`}</a>{`を見ればすぐに構築可能です）。`}</p>
    <p>{`余談ですが、 Terraform で Aurora クラスターを作成する時、 `}<a parentName="p" {...{
        "href": "https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/rds_cluster"
      }}>{`aws_rds_cluster`}</a>{` に記載されているサンプルでは、暗号化設定が記述にはないので作成する時は気をつけた方が良さそうです。`}</p>
    <p><strong parentName="p">{`3. 旧クラスターを読み取り専用に変更する`}</strong></p>
    <p>{`旧クラスターに紐づいているパラメーターグループの `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`default_transaction_read_only`}</code>{` を 0 から 1 に変更します。この作業が完了すると旧クラスターへの書き込み処理はされなくなります。`}</p>
    <p><strong parentName="p">{`4. 移行スクリプトを実行する（実行時間： 3 分強）`}</strong></p>
    <p>{`移行するためのスクリプトを実行します。
実際に実行した時には、いくつかのオプションをつけましたが、基本的にやったことは、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_dump`}</code>{` したものを暗号化された Aurora DB 新クラスターに `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_restore`}</code>{` するだけです。
2 で構築した新クラスターは、旧クラスターと同じプライベートサブネットに作成しておけば旧クラスターにアクセスできる状態になるので、移行コマンドは問題なく実行できると思います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sh"
    }}><pre parentName="div" {...{
        "className": "language-sh"
      }}><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`$ pg_dump -h <旧クラスターのホスト名> -p <ポート番号> -U <ユーザー名> -d <データベース名> | \\
    pg_restore -h <新クラスターのホスト名> -U <ユーザ名> -d <データベース名>`}</code></pre></div>
    <p><strong parentName="p">{`5. DNS Alias を旧クラスターから新クラスターに切り替える（実行時間：数秒）`}</strong></p>
    <p>{`移行が完了したら、 1 で作成した DNS Alias を新クラスターに向くように変更します。
移行時は TTL（ Time to Live ）はできるだけ短く設定（ 15 秒）することで、ホストの切り替えに時間がかからないようにします。`}</p>
    <p><strong parentName="p">{`6. AWS Fargate サービスを再起動する（実行時間： 3 分強）`}</strong></p>
    <p>{`ホストが切り替わったら、AWS Fargate サービスを再起動します（以下コマンドを実行するだけ）。
先述しましたが、本作業を行わないと Rails から Aurora DB クラスターへの DB コネクションプールが残ってしまいます。
すでにホスト名は変更されているためコネクションが失敗すると自動で新しく接続をし直し、失敗後は意図したホスト（暗号化済みの新クラスター）に接続されますが、接続先を必ず変更したいので、サービスの再起動を行います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sh"
    }}><pre parentName="div" {...{
        "className": "language-sh"
      }}><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`$ aws ecs update-service --profile <プロファイル> \\
    --cluster <クラスター名> \\
    --service <サービス名> \\
    --force-new-deployment`}</code></pre></div>
    <p>{`本作業は検証時に DNS Alias を変更しただけだと接続先が変わらないことに気付き入れた対応でした。`}</p>
    <p>{`ここまでの作業が完了したら実際に動かしているアプリケーションに不具合がないかを確認し作業完了です ✨`}</p>
    <h1>{`さいごに`}</h1>
    <h2>{`まとめ`}</h2>
    <p>{`今回は稼働中の Amazon Aurora PostgreSQL を暗号化した時の検証・実施内容を紹介しました。`}</p>
    <p>{`今回の方法は、対象のプロダクトで行う上での一例ですが、比較的少ないダウンタイムで作業を完了することができました。`}</p>
    <p>{`また、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_dump`}</code>{` と `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pg_restore`}</code>{` 実行時に並列度を増やすことでもっと早く移行作業は完了できると思いますが、暗号化するデータの容量が大きくない、Aurora クラスター側に負荷をかける必要がなかったので今回は 1 で実行しました。`}</p>
    <p>{`私自身本作業を終えて、パラメータグループの細かい設定についての知見も増え、この後にやった他の施策にも活きているので自分自身の成長にも繋がりました。
先輩方に意見を求めたりもしましたが、施策自体は 1 人でやり切れたことはとても良い経験になりました。
普段は、 AWS のマネージドサービス内で行えないかを真っ先に考えるのですが、マネージドサービスに依存しない形で行うこともユースケースによっては必要だなと再認識できた良い経験でした。
少しでも私の経験が誰かの役に立てばうれしいなと思います。`}</p>
    <p>{`このように、メドレーは新卒・中途に関わらず裁量を持って施策を実行できる環境です。インフラやアプリケーションにとらわれず様々なプロジェクトに携われる開発チームにジョインしてみませんか？`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      